@use "@angular/material" as mat;

@mixin global-theme($theme) {
  $background: map-get($theme, "background");
  $foreground: map-get($theme, "foreground");
  $is-dark-theme: map-get($theme, "is-dark");

  @if $is-dark-theme {
    background-color: mat.get-color-from-palette($background, "card");
    color: mat.get-color-from-palette($foreground, "text");
  } @else {
    body {
      background-color: mat.get-color-from-palette($background, "card");
      color: mat.get-color-from-palette($foreground, "text");
    }
  }
}
