@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;

@include mat.ripple();
@include cdk.a11y-visually-hidden();
@include cdk.overlay();
@include cdk.text-field();

// FIXME
mat.$theme-ignore-duplication-warnings: true;

@mixin hls-theme($theme) {
  @include global-theme($theme);
  @include material-theme($theme);
}

@mixin hls-typography($config) {
  @include material-typography($config);
}

@include hls-typography($hls-typography-config);

@include hls-theme($hls-light-theme);

.dark {
  @include hls-theme($hls-dark-theme);
}
