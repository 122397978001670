@use "@angular/material" as mat;

@mixin material-theme($theme) {
  @include mat.button-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.core-theme($theme);
  @include mat.divider-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.list-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.progress-spinner-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.card-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.select-theme($theme);
  @include mat.form-field-theme($theme);

  @include mat.datepicker-theme($theme);

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @include mat.table-theme($theme);

  .mat-mdc-table {
    background-color: inherit;
  }

  @include mat.tooltip-theme($theme);
  @include mat.tree-theme($theme);

  .mat-tree {
    background-color: inherit;
  }
}

@mixin material-typography($config) {
  @include mat.typography-hierarchy($config);
  @include mat.button-typography($config);
  @include mat.checkbox-typography($config);
  @include mat.icon-typography($config);
  @include mat.list-typography($config);
  @include mat.menu-typography($config);
  @include mat.progress-spinner-typography($config);
  @include mat.sidenav-typography($config);
  @include mat.slide-toggle-typography($config);
  @include mat.table-typography($config);
  @include mat.tooltip-typography($config);
  @include mat.tree-typography($config);
  @include mat.datepicker-typography($config);
  @include mat.snack-bar-typography($config);
}
